import {useRouter} from 'next/router'
import {useEffect} from 'react'

// let folder = 'master_rilis'


export default function Index() {
  const router = useRouter()
  useEffect(() => {
    router.push('/dashboard')
  }, []) /* eslint-disable-line */
}
